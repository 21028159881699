@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Nunito, sans-serif;
  /* outline: 3px solid gold; */
}

a {
  text-decoration: none;
}